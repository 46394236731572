<template>
  <div id="master-data-user-add">
    
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Tambah Data
          </h3>
        </div>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <ValidationObserver ref="formAdd">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Nama Lengkap</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Nama" rules="required|min:5|max:50" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-user-1" 
                            label-for="input-user-1"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                            id="input-user-1"
                            v-model="form.name"
                            class="form-control form-control-solid"
                            required
                            size="lg"
                            placeholder="Nama Lengkap"
                            :state="valid"
                            ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Username</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Username" rules="required|username|min:3|max:100" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-user-2" 
                            label-for="input-user-2"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                            id="input-user-2"
                            v-model="form.username"
                            class="form-control form-control-solid"
                            required
                            size="lg"
                            placeholder="Username"
                            :state="valid"
                            ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Email" rules="required|email|max:255" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-user-3" 
                            label-for="input-user-3"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                            id="input-user-3"
                            v-model="form.email"
                            type="email"
                            class="form-control form-control-solid"
                            required
                            size="lg"
                            placeholder="Email"
                            :state="valid"
                            ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-6">Akses User:</h5>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Tipe User</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Tipe User" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-user-4" 
                            label-for="input-user-4"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-radio-group 
                            id="input-user-4" 
                            v-model="form.tipe" 
                            name="user-tipe">
                              <b-form-radio v-for="(item, index) in opsTipe" :key="index" :value="item.value">
                                {{ item.text }}
                              </b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Role</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Role" rules="required" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-user-5" 
                            label-for="input-user-5"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                              <b-form-select2
                              id="input-user-5"
                              v-model="form.role"
                              required
                              variant="solid"
                              size="lg"
                              :options="opsRole"
                              placeholder="Silahkan pilih Role"
                              :state="valid"
                              ></b-form-select2>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-3">Komoditas:</h5>
                </div>
            </div>   
            <b-row v-for="(rinc, index) in form.komoditas" :key="index">
              <b-col cols="10" class="pb-0 pt-0">
                <h5 class="mt-4 text-muted">Rincian {{index + 1}}</h5>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button v-show="(form.komoditas.length > 1) ? true : false" @click="deleteKomoditas(index)" size="sm" variant="link" class="text-hover-primary">
                  <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                </b-button>
              </b-col>
              <b-col cols="12" sm="6">
                <validationProvider :name="`Rin ${index+1} Komoditas`" rules="required" v-slot="{ valid, errors }">
                  <b-form-group 
                  :id="`input-group-5-${index}-1`" 
                  label="Komoditas:" 
                  :label-for="`input-5-${index}-1`"
                  :invalid-feedback="errors[0]"
                  :state="valid">    
                    <b-form-select2
                      :id="`input-5-${index}-1`"
                      v-model="rinc.komoditas"
                      variant="solid"
                      size="lg"
                      @change="onChangeKomoditas(index)"
                      :options="opsTernakKomoditas"
                      required
                      placeholder="Silahkan pilih Komoditas"
                      :state="valid"
                    ></b-form-select2>
                  </b-form-group>
                </validationProvider>
              </b-col>
              <b-col cols="12" sm="6">
                <validationProvider :name="`Rin ${index+1} Tipe`" rules="required" v-slot="{ valid, errors }">
                  <b-form-group 
                  :id="`input-group-5-${index}-2`" 
                  label="Tipe:" 
                  :label-for="`input-5-${index}-2`"
                  :invalid-feedback="errors[0]"
                  :state="valid">          
                    <b-form-select2
                      :id="`input-5-${index}-2`"
                      v-model="rinc.tipe"
                      variant="solid"
                      size="lg"
                      :options="getTipeByKomoditas(rinc.komoditas)"
                      required
                      placeholder="Silahkan pilih Tipe"
                      :state="valid"
                    ></b-form-select2>
                  </b-form-group>
                </validationProvider>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" class="text-right">
                <b-button @click="addKomoditas" variant="link" class="text-hover-primary">
                  <i class="flaticon2-plus-1 p-0"></i>
                  Add Komoditas
                </b-button>
              </b-col>
            </b-row>

            <!--begin::Form Group-->
            <div class="separator separator-dashed my-5 mt-15"></div>
            <!--begin::Form Group-->
            <div class="row">
                <label class="col-xl-3"></label>
                <div class="col-lg-9 col-xl-7">
                    <h5 class="font-weight-bold mb-6">Password:</h5>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Password</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Password" rules="required|password|min:8|max:150" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-user-6" 
                            label-for="input-user-6"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                            id="input-user-6"
                            v-model="form.password"
                            class="form-control form-control-solid"
                            required
                            type="password"
                            size="lg"
                            placeholder="Password"
                            :state="valid"
                            ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Confirmation</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Password Confirmation" rules="required|min:8|max:150|matchPassword:@Password" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-user-7" 
                            label-for="input-user-7"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                            autocomplete="off"
                            id="input-user-7"
                            v-model="form.password_confirmation"
                            class="form-control form-control-solid"
                            required
                            type="password"
                            size="lg"
                            placeholder="Password Confirmation"
                            :state="valid"
                            ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>
            
          </b-form>
          </ValidationObserver>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <button 
                @click="onSubmit" 
                ref="kt_submit"
                class="btn btn-primary font-weight-bold"
              >
                <i class="la la-save"></i>
                Simpan
              </button>
              &nbsp;
              <button
                @click="onReset"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Reset
              </button>
            </div>
          </div>
        </div>
      <!--end::Form-->
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import RoleService from "@/core/services/api/master/role.service";
import UserService from "@/core/services/api/master/user.service";
import ErrorService from "@/core/services/error.service";
import { extend } from 'vee-validate';

extend('matchPassword', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend('password', {
  validate(value) {
    const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{1,}$/
    return value.match(validPassword);
  },
  message: 'Password must use a combination of uppercase (A-Z), lowercase (a-z) and numbers (0-9).'
});

extend('username', {
  validate(value) {
    const validUsername = /^(?!.*__.*)(?!.*\.\..*)[a-z0-9_.]+$/
    return value.match(validUsername);
  },
  message: 'Username only letters (a-z), numbers (0-9), underscores (_) and periods (.) Are allowed.'
});

export default {
  name: "master-data-user-add",
  data() {
    return {
      form: {
        name: null,
        username: null,
        email: null,
        tipe: null,
        role: null,
        password: null,
        password_confirmation: null,
        komoditas: [{
          komoditas: null,
          tipe: null
        }],
      },
      opsTipe: [],
      opsRole: [
        { text: 'Silahkan Pilih Role', value: null },
      ],
      opsTernakKomoditas: [{
        value: null,
        text: "Silahkan pilih Komoditas"
      }],
      opsTernakTipe: [{
        value: null,
        text: "Silahkan pilih Tipe"
      }],
      show: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    dataUserSet () {
      if (!this.form.tipe) return
      const fd = this.opsTipe.find((v)=>{ return v.value == this.form.tipe}).fd
      if (fd == 'all') return 'perusahaan'
      return fd
    },
    opsGroupTipe () {
      return this.opsTernakTipe.reduce((acc, obj) => {
        const property = obj['komoditas'];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    },
  },
  methods: {
    addKomoditas(){
      this.form.komoditas.push({
            komoditas: null,
            tipe: null
          })
    },
    deleteKomoditas(index){
      if (this.form.komoditas.length > 1) {
        this.form.komoditas.splice(index,1);
      }
      else {
        this.form.komoditas = [
          {
            komoditas: null,
            tipe: null
          }
        ]
      }
    },
    onChangeKomoditas(index) {
      this.form.komoditas[index].tipe = null
    },
    getTipeByKomoditas(id) {
      let data = [{ text: 'Silahkan pilih Tipe', value: null }]
      if(!id) return data
      return [...data, ...this.opsGroupTipe[id]]
    },
    getTernakKomoditas() {
      MasterService.getTernakKomoditas()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakKomoditas = [{
                  value: null,
                  text: "Silahkan pilih Komoditas"
                }]
                return;
            }

            this.opsTernakKomoditas = res.data.data
            this.opsTernakKomoditas.push({
              value: null,
              text: "Silahkan pilih Komoditas"
            })
        })
        .catch((err) => {
          this.opsTernakKomoditas = [{
            value: null,
            text: "Tidak ada Komoditas"
          }]
          ErrorService.status(err)
        })
    },
    getTernakTipe() {
      MasterService.getTernakTipe({ child: 1 })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTernakTipe = [{
                  value: null,
                  text: "Silahkan pilih Tipe"
                }]
                return;
            }

            this.opsTernakTipe = res.data.data
            this.opsTernakTipe.push({
              value: null,
              text: "Silahkan pilih Tipe"
            })
        })
        .catch((err) => {
          this.opsTernakTipe = [{
            value: null,
            text: "Tidak ada Tipe"
          }]
          ErrorService.status(err)
        })
    },
    getTipeUser() {
      MasterService.getTipeUser()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTipe = []
                return;
            }

            this.opsTipe = res.data.data
        })
        .catch((err) => {
          this.opsTipe = []
          ErrorService.status(err)
        })
    },
    getRole() {
      RoleService.getRole()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsRole = [{
                  value: null,
                  text: "Silahkan Pilih Role"
                }]
                return;
            }

            this.opsRole = res.data.data
            this.opsRole.push({
              value: null,
              text: "Silahkan Pilih Role"
            })
        })
        .catch((err) => {
          this.opsRole = [{
            value: null,
            text: "Tidak ada Role"
          }]
          ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            UserService.addUser(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.$router.push({ name: 'master-data-user-edit', params: { idUS: res.data.data.uuid }, query: { tab: this.dataUserSet } })
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      
        this.form.name = null
        this.form.username = null
        this.form.email = null
        this.form.tipe = null
        this.form.role = null
        this.form.password = null 
        this.form.password_confirmation = null 
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Farm" }]);
    if (!this.currentUser.menu.user) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getTernakKomoditas()
    this.getTernakTipe()
    this.getTipeUser()
    this.getRole()
  },
};
</script>